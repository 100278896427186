import React, {useState, useEffect, useContext} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
    CustomizedButton,
    Container,
    CustomizedForm,
    CustomizedSubtitle,
    DeaultLink,
    Text,
    TitleBox
} from './styles';
import { Title } from '../../components/common/styles/styles';
import { ButtonLabel } from '../../components/common/buttons/styles';
import { DottedLoading } from '../../components/common/loading/styles';
import StyledInput from '../../components/common/inputs/styled-input/StyledInput'
import useForm from '../../hooks/useForm'
import useStatus from '../../hooks/useStatus'
import {auth, isAuthenticated, signOut} from '../../services/AuthService'
import user from '../../assets/user.svg'
import key from '../../assets/key.svg'
import { CSSTransition } from 'react-transition-group';
import './styles.css';
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {getWallet} from "../../services/WalletService";
import {Context} from "../../contexts/ContextProvider";

const Login = () => {
    // signOut()
    let history = useHistory();
    const { width } = useWindowDimensions();
    const initialKey = { id: '', password: '' }

    const { values, setValue } = useForm(initialKey)
    const { status, setAllStatus } = useStatus()
    const [showContainer, setShowContainer] = useState(false);
    const context = useContext(Context)
    
    useEffect(() => {
        if(isAuthenticated()) history.push("/minha-conta");
        setShowContainer(true);
    }, [])

    const basicAuth = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setAllStatus({ isFetching: true, success: false, error: false })
        try {
            const key: any = values
            await auth(key)
            await getCustomerWallet()
            setAllStatus({ isFetching: false, success: true, error: false })
            history.push("/")
        } catch (err) {
            console.log(err)
            setAllStatus({ isFetching: false, success: false, error: true })
            toast.error('Ops! Não foi possível realizar o login, verifique se usuário e senha estão corretos.');
        }
    }
    
    const getCustomerWallet = async () => {
        try {
            const wallet = await getWallet();
            if(wallet) {
                context.setWallet(wallet)
            }
            
        } catch (error) {
            console.log(error)
        }
        
    }

    const breakLine = () => {
        return width > 700 ? null : (<br/>)
    }

    return (
        <>
            <ToastContainer/>
            <Container>
                {
                    status.isFetching ? (
                    <div style={{ margin: 'auto' }}>
                        <DottedLoading />
                    </div>
                ) :
                        (

                            <>
                                <TitleBox>
                                    <Title>Olá! Seja bem-vindo {breakLine()} ao EasyBind4U</Title>
                                    <CustomizedSubtitle>Entre com seu nome {breakLine()} de usuário e senha.</CustomizedSubtitle>
                                </TitleBox>
                                <CustomizedForm onSubmit={(e: React.FormEvent<HTMLFormElement>) => basicAuth(e)}>
                                    <StyledInput
                                        icon={user}
                                        name={'id'}
                                        placeholder={"Email"}
                                        onchange={setValue} />
                                    <StyledInput
                                        icon={key}
                                        name={'password'}
                                        placeholder={"Senha"}
                                        type={'password'}
                                        onchange={setValue}
                                        showeye
                                    />
                                    <DeaultLink to="/esqueceu-senha">Esqueceu a senha?</DeaultLink>
                                    <CustomizedButton style={{ border: 0 }}>
                                        <ButtonLabel>Entrar</ButtonLabel>
                                    </CustomizedButton>
                                    <Text>Não tem uma conta? <DeaultLink to="/cadastro">Cadastre-se</DeaultLink></Text>
                                </CustomizedForm>
                            </>

                        )
                }
            </Container>
        </>

    )
}

export default Login